import React, { useContext } from 'react'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import FormikWithShowingErrors from "./FormikWithShowingErrors";

const phoneRegExp = /(\(?([\d \-)–+/\\(]+){6,}\)?([ .\-–/]?)([\d]+))/

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('Vorname ist ein Pflichtfeld'),
  lastName: yup
    .string()
    .required('Nachnahme ist ein Pflichtfeld'),
  email: yup
    .string()
    .email('Geben Sie eine gültige Email-Adresse ein')
    .required('Email ist ein Pflichtfeld'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, { message: 'Geben Sie eine gültige Telefonnummer ein', excludeEmptyString: true })
})

interface ProfileFormProps {
  setEditMode: (editMode: boolean) => void
}

const ProfileForm = (props: ProfileFormProps) => {

  const { setEditMode } = props
  const { user } = useContext(UserContext)

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" pb={3}>
          Persönliche Informationen
        </Typography>
        <FormikWithShowingErrors
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            phoneNumber: user?.phoneNumber || '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            user && await firebaseSetUser({
              values: values,
              actionType: 'updateProfileData',
              user: user,
              onSuccess: () => setEditMode(false)
            })
          }}
        >
          {formik => (
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <Box pb={3}>
                <Avatar sx={{ height: 100, width: 100 }}></Avatar>
                <Typography>Ersetzen</Typography>
              </Box>

              <Typography variant="h5">Vorname</Typography>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="Vorname"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Nachname</Typography>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Nachname"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Email</Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Telefonnummer</Typography>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="Telefonnummer"
                type="text"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Button color="primary" variant="contained" type="submit">
                Speichern
              </Button>
            </Box>
          )
          }
        </FormikWithShowingErrors>
      </CardContent>
    </Card>
  )
}

export default ProfileForm