import React from 'react'
import Card, { CardProps } from '@mui/material/Card'
import { styles } from '../styles/styles'

interface DescriptionCardProps extends CardProps {
}

const DescriptionCard = (props: DescriptionCardProps) => {
  const { children } = props
  return (
    <Card sx={{ backgroundColor: styles.PRIMARY_COLOR_ACTIVE, color: styles.WHITE }} {...props}>{children}</Card>
  )
}

export default DescriptionCard