import React, { useContext } from 'react'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Close from '@mui/icons-material/Close'
import { theme } from '../../styles/theme'
import { TextField } from '@mui/material'
import * as yup from 'yup'
import FormikWithShowingErrors from '../../forms/FormikWithShowingErrors'
import { firebaseCreateInvite } from '../../services/invite'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'

interface InviteModalProps {
  open: boolean
  handleClose: () => void
}

const boxStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '1rem',
  minWidth: '300px',
}

const validationSchema = yup.object({
  email: yup.string().email('Bitte eine gültige E-Mail-Adresse eingeben').required('E-Mail-Adresse ist erforderlich'),
})

const InviteModal = (props: InviteModalProps) => {

  const { open, handleClose } = props
  const { showSuccessMessage, showErrorMessage } = useContext(ShowMessageContext)

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box bgcolor={theme.palette.background.default} sx={boxStyle} px={2} py={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Kontakte einladen</Typography>
          <IconButton>
            <Close onClick={() => { handleClose() }} />
          </IconButton>
        </Box>
        <Typography variant="body1" pt={2}>
          Laden Sie Personen in Ihr Kontaktbuch ein.
        </Typography>
        <Typography variant="h6" pt={2} pb={1}>
          E-Mail
        </Typography>
        <FormikWithShowingErrors
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const res = await firebaseCreateInvite({ email: values.email })
            if (res?.success) {
              showSuccessMessage(res?.message as string)
            } else {
              showErrorMessage(res?.message)
            }
            handleClose()
          }}
        >
          {(formik) => (
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Box display="flex" justifyContent="flex-end" pt={2}>
                <Button variant="contained" type="submit">Einladen</Button>
              </Box>
            </Box>
          )}
        </FormikWithShowingErrors>
      </Box>
    </Modal >
  )
}

export default InviteModal