import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { ContactRequest } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import ContactCard from '../ContactCard'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Card, Modal } from '@mui/material'


const ConnectModal = (props: { request: ContactRequest, handleSubmit: Function }) => {

  const { request, handleSubmit } = props;

  const { user } = useContext(UserContext)
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Modal open={open}>
      <Box px={2} sx={{
        boxSizing: 'border-box',
        backgroundColor: 'background.default',
        width: '100%',
        minHeight: '100%',
      }}
      >
        <Box display="flex" justifyContent="space-between" sx={{ pt: 2, px: 1 }}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBack />
          </IconButton>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection={"column"} alignItems="center">
          <Typography variant="h5">
            {request.title}
          </Typography>
          <ContactCard contactRef={request.requesterId} />
        </Box>
        <Box>
          <Typography>
            Laden Sie neue Kontakte ein um die für die Anfrage zu empfehlen.
          </Typography>
          <Button variant="contained" component={Link} to={`/vermitteln/${request._id}/new`}>Neue Kontakte einladen</Button>
        </Box>
        <Box>
          <Typography>
            Unsere Empfehlungen für Kontakte
          </Typography>
          <Typography variant="body2">
            Hier können sie Kontakte aus Ihrem Kontaktbuch zur Vernetzung auswählen.
          </Typography>
          {user?.contacts?.filter(c => c !== request.requesterId).map(c => (
            <Card sx={{my: 2, p: 2}}>
              <ContactCard contactRef={c} />
              {/* <Button variant="contained" component={Link} to={`/vermitteln/${r._id}/${c}`}>Kontakt vorschlagen</Button> */}
              <Button variant="contained" onClick={() => handleSubmit(c)}>Kontakt vorschlagen</Button>
            </Card>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default ConnectModal