import { useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { UserContext } from '../../contexts/UserContext'

const ProfileCard = () => {

  const { user } = useContext(UserContext)

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" pb={2}>
          Persönliche Daten
        </Typography>
        <Box display={'flex'}>
          <Avatar sx={{ height: 100, width: 100 }}></Avatar>
          <Box pl={2}>
            <Typography variant="h6">
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body1">
              {user?.email}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ProfileCard