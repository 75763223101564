import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBijnkAC8N8pbhgt963hwa4EIUgKKN1xdg",
    authDomain: "nachfolgerforum.firebaseapp.com",
    projectId: "nachfolgerforum",
    storageBucket: "nachfolgerforum.appspot.com",
    messagingSenderId: "202134189261",
    appId: "1:202134189261:web:908dbf6de4cc2a9bb0e584",
    measurementId: "G-85T1JELBCL"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const firebaseFunctions = getFunctions(app, "europe-west3");

// for local development
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectFunctionsEmulator(functions, "localhost", 5001);

// setPersitence

if (typeof document !== "undefined") {
    setPersistence(auth, browserLocalPersistence)
        .then(() => {
            console.log('Persistence set to LOCAL');
        })
        .catch((error) => {
            console.log('Persistence set to LOCAL failed');
        });
}


