import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import FormikWithShowingErrors from './FormikWithShowingErrors'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ContactRequest, DocRef } from '../types/dreico.types'
import { firebaseCreateConnection } from '../services/connections'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../routes/Routes'
import { ShowMessageContext } from '../contexts/ShowMessageContext'


const validationSchema = yup.object({
  message: yup.string().required('Message is required'),
})

interface ConnectContactFormProps {
  requesterId: DocRef
  connectedUserId: DocRef
  requestId: DocRef
  request: ContactRequest
}

const ConnectContactsForm = (props: ConnectContactFormProps) => {

  const { requesterId, connectedUserId, requestId, request } = props
  const navigate = useNavigate()

  const { showErrorMessage } = useContext(ShowMessageContext)

  return (
    <Box sx={{ px: 2 }}>
      <Typography>
        Nachricht
      </Typography>
      <Typography>
        Ihre Nachricht an die beiden vernetzen Personen.
      </Typography>
      <FormikWithShowingErrors
        initialValues={{
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          firebaseCreateConnection({
            values: values,
            request: request,
            requesterId: requesterId,
            connectedUserId: connectedUserId,
            requestId: requestId,
            onSuccess: () => navigate(AppRoutes.DASHBOARD),
            showErrorMessage: showErrorMessage,
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit} display="flex" flexDirection="column" sx={{ pb: 2 }}>
            <TextField
              multiline
              minRows={4}
              id="message"
              name="message"
              label="Nachricht"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={() => {

                }}
              >
                zurück
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Vernetzen
              </Button>
            </Box>

          </Box>
        )}
      </FormikWithShowingErrors>
    </Box>
  )
}

export default ConnectContactsForm