import React, {useContext, useEffect, useState} from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { Tag } from '../types/dreico.types'
import { createTagIfNotExists, getAllTags } from '../services/tags'
import { CustomAutocompleteProps } from '../types/forms.types'
import {ShowMessageContext} from "../contexts/ShowMessageContext";

interface TagInputProps extends CustomAutocompleteProps {
  value: Tag[]
  helperText?: string[] | string | false
}

const TagInput = (props: TagInputProps) => {

  const [tags, setTags] = useState<Tag[]>([])
  const { value, formik } = props

  const { showErrorMessage } = useContext(ShowMessageContext)

  useEffect(() => {
    getAllTags({}).then((tags) => {
      setTags(tags || [])
    })
  }, [])

  const onChange = (event: React.ChangeEvent<any>, chosenTagNames: string[]) => {
    const chosenTags = chosenTagNames.map(tagName => ({ name: tagName } as Tag))
    formik.setFieldValue('tags', chosenTags)

    chosenTags.forEach(formTag => {
      // Ensure that any tag entered by user exists in persistence layer.
      createTagIfNotExists({ newTag: formTag }).catch(error => {
        showErrorMessage(error)
      })
    });
  }

  return (
    <Autocomplete
      id="tags"
      multiple
      freeSolo
      options={tags.map(tag => tag.name) || []}
      value={value.map(tag => tag.name) || []}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          name="tags"
          {...params}
          error={props.error}
          helperText={props.helperText}
          label="Tags" placeholder="" />
      )}
    />
  )
}

export default TagInput