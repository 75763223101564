import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { UserContext } from '../../contexts/UserContext'
import { Connection, ContactRequest } from '../../types/dreico.types'
import ListOfConnections from './ListOfConnections'

export interface ConnectionListItem {
  createdAt: Date
  request: ContactRequest | null
  connection: Connection[]
}

const Connections = () => {

  const { connections } = useContext(UserContext)

  // const listItems = useMemo(() => {
  //   const items: ConnectionListItem[] = []
  //   const connectionsAsConnectedUser = connections.filter((connection) => connection.connectedUserId === user?._id)

  //   connectionsAsConnectedUser.forEach((connection) => {
  //     items.push({
  //       createdAt: connection.createdAt,
  //       connection: [connection],
  //       request: null
  //     })
  //   })

  //   requestsAsRequester.forEach((request) => {
  //     items.push({
  //       createdAt: request.createdAt,
  //       request: request,
  //       connection: connections.filter((connection) => connection.requestId === request._id),
  //     })
  //   })

  //   return items.sort((a, b) => {
  //     return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  //   })
  // }, [connections, requestsAsRequester])

  return (
    <Box display="flex" flexDirection="column">
      {/* {listItems.map((listItem) => (
        <ConnectionList key={listItem.createdAt.toString()} listItem={listItem} />
      ))} */}
      {connections.map(
        connection => <ListOfConnections connection={connection} />
      )}

    </Box>
  )
}

export default Connections