import { auth, db } from './firebase'
import { doc, setDoc, arrayUnion, onSnapshot, collection, where, query } from "firebase/firestore"
import { ContactRequest } from "../types/dreico.types"
import { ErrorWithDetails } from '../utility/error-handling/error-handling-utility'
import { v1 as uuidv1 } from 'uuid'
import { CreateRequestData } from '../types/actions.types'

interface CreateRequestVars {
  values: CreateRequestData
  onSuccess?: () => void
  showErrorMessage?: (error: any, userFriendlyMessage?: string | undefined) => void
}

export const firebaseCreateRequest = async (vars: CreateRequestVars) => {
  /** 
   * Create a new request.
   */
  const { values, onSuccess, showErrorMessage } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const id = uuidv1()

  const requestRef = doc(db, "requests", id)
  const userRef = doc(db, "users", auth.currentUser.uid, "private", "userData")

  const request = {
    _id: id,
    _type: 'request',
    title: values.title,
    description: values.description,
    createdAt: (new Date()).getTime(),
    status: 'open',
    requesterId: auth.currentUser.uid,
    connectorIds: values.connectorIds,
    requestOntology: {
      _type: 'requestOntology',
      businessType: values.businessType,
      industries: values.industries,
      tags: values.tags,
      location: values.location,
    }
  }

  try {
    await setDoc(requestRef, request)
    await setDoc(userRef, {
      requests: arrayUnion({
        id: id,
        view: 'new',
        role: 'connector'
      })
    }, { merge: true })
    onSuccess && onSuccess()
  }
  catch (error) {
    showErrorMessage && showErrorMessage(new ErrorWithDetails(error, "create request error"))
  }
}

interface GetRequestVars {
  setRequests: (requests: ContactRequest[]) => void
}

export const firebaseGetRequestsAsRequester = async (vars: GetRequestVars) => {
  /**
   * Get all requests for the current user.
   */
  const { setRequests } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const q1 =
    query(collection(db, "requests"), where("requesterId", "==", auth.currentUser.uid))

  onSnapshot(q1, (querySnapshot) => {
    const requests: ContactRequest[] = []
    querySnapshot.forEach((doc) => {
      requests.push(doc.data() as ContactRequest)
    })
    setRequests && setRequests(requests)
  })
}

export const firebaseGetRequestsAsConnector = async (vars: GetRequestVars) => {
  /**
   * Get all requests for the current user.
   *  
   */
  const { setRequests } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const q1 =
    query(collection(db, "requests"), where("connectorIds", "array-contains", auth.currentUser.uid))

  onSnapshot(q1, (querySnapshot) => {
    const requests: ContactRequest[] = []
    querySnapshot.forEach((doc) => {
      requests.push(doc.data() as ContactRequest)
    })
    setRequests && setRequests(requests)
  })
}