import React from 'react'
import Box from '@mui/material/Box'
import * as yup from 'yup'
import { Formik } from 'formik'
import RequestDescriptionForm from './RequestDescriptionForm'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import SelectConnectorForm from './SelectConnectorForm'
import { firebaseCreateRequest } from '../../services/request'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'


const validationSchema = [
  yup.object({
    title: yup.string().required('Ein Titel ist notwendig').max(120, 'Der Titel darf nicht länger als 120 Zeichen sein'),
    // desiredResult: yup.string().required('Ein Ziel ist notwendig').max(120, 'Das Ziel darf nicht länger als 120 Zeichen sein'),
    description: yup.string().required('Bitte geben Sie eine Beschreibung an').max(1000, 'Die Beschreibung darf nicht länger als 1000 Zeichen sein'),
    businessType: yup.array().min(1, 'Bitte geben Sie mindestens eine Auswahl an'),
    location: yup.array().min(1, 'Bitte geben Sie mindestens eine Auswahl an'),
    industries: yup.array().min(1, 'Bitte geben Sie mindestens eine Auswahl an'),
    tags: yup.array().min(1, 'Bitte geben Sie mindestens eine Auswahl an'),
  }),
  yup.object({
    connectorIds: yup.array().min(1, 'Bitte geben Sie mindestens eine Auswahl an')
  })
]

const initialValues =
{
  title: '',
  // desiredResult: '',
  description: '',
  businessType: [],
  location: [],
  industries: [],
  tags: [],
  connectorIds: []
}

interface ContactRequestFormProps {
  step: number
  setStep: (step: number) => void
}

const ContactRequestForm = (props: ContactRequestFormProps) => {

  const { step, setStep } = props
  const navigate = useNavigate()
  const { showErrorMessage, showSuccessMessage } = React.useContext(ShowMessageContext)

  const _submitForm = (values: any, actions: any) => {
    firebaseCreateRequest({
      values: values,
      onSuccess: () => {
        showSuccessMessage('Ihre Anfrage wurde erfolgreich gesendet')
        navigate('/dashboard')
      },
      showErrorMessage: showErrorMessage,
    })
  }

  return (
    <>
      <Formik
        initialValues={
          initialValues
        }
        validationSchema={validationSchema[step]}
        onSubmit={(values, actions) => {
          if (step === 1) {
            _submitForm(values, actions);
          } else {
            setStep(step + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit} id="contact-request">
            { // @ts-ignore
              step === 0 ? <RequestDescriptionForm formik={formik} /> : <SelectConnectorForm formik={formik} />
            }
            {step === 0 ? (
              <Button onClick={() => navigate(-1)}>abbrechen</Button>
            ) :
              (
                <Button onClick={() => setStep(step - 1)} startIcon={<ArrowBack />}>zurück</Button>
              )}
            <Button
              variant="contained"
              endIcon={step === 0 ? <ArrowForward /> : undefined}
              type="submit"
            >
              {step === 0 ? 'Connectoren finden' : 'Anfrage senden'}
            </Button>
          </Box>
        )}
      </Formik>
    </>
  )
}

export default ContactRequestForm