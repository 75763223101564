import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormikWithShowingErrors from "../forms/FormikWithShowingErrors";
import { FormikValues } from "formik";
import { ReactComponent as LogoDark } from '../assets/logo-blue.svg';

const phoneRegExp = /(\(?([\d \-)–+/\\(]+){6,}\)?([ .\-–/]?)([\d]+))/

const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('Vorname ist ein Pflichtfeld'),
    lastName: yup
        .string()
        .required('Nachname ist ein Pflichtfeld'),
    email: yup
        .string()
        .email('Geben Sie eine gültige Email-Adresse ein')
        .required('Email ist ein Pflichtfeld'),
    phoneNumber: yup
        .string()
        .matches(phoneRegExp, { message: 'Geben Sie eine gültige Telefonnummer ein', excludeEmptyString: true }),
    password: yup
        .string()
        .min(8, 'Das Passwort sollte mindestens 8 Zeichen lang sein.')
        .required('Passwort ist ein Pflichtfeld'),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
        .required('Passwort bestätigen ist ein Pflichtfeld'),
    allowExtraEmails: yup.boolean()
        .required('Sie müssen der Datenschutzbestimmung zustimmen')
});

interface RegistrationFormProps {
    onSubmit: (values: FormikValues) => void
}

const RegistrationForm = (props: RegistrationFormProps) => {

    return (
        <Box
            sx={{
                m: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box width={'18rem'} height={'12rem'}>
                <LogoDark width={'100%'} height={'100%'} />
            </Box>
            <Typography component="h1" variant="h5" mt={2}>
                Registrieren Sie sich!
            </Typography>
            <FormikWithShowingErrors
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    password: '',
                    passwordConfirmation: '',
                    allowExtraEmails: false,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => { await props.onSubmit(values) }}
            >
                {formik => (
                    <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="Vorname"
                                    type="text"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Nachname"
                                    type="text"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    autoComplete="tel"
                                    label="Telefonnummer"
                                    type="tel"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    label="Confirm Password"
                                    type="password"
                                    value={formik.values.passwordConfirmation}
                                    onChange={formik.handleChange}
                                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={formik.values.allowExtraEmails}
                                                color="primary" />}
                                        label="Ich stimme der Datenschutzerklärung zu"
                                    />
                                    {/* <FormHelperText>Bitte stimme der Datenschutzerklärung zu.</FormHelperText> */}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {formik.isSubmitting ? <CircularProgress /> : "Sign Up"}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </FormikWithShowingErrors>
        </Box>
    );
};

export default RegistrationForm;
