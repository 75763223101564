import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Chip from '@mui/material/Chip'
import ContactList from './ContactList'
import Fab from '@mui/material/Fab'
import InviteModal from './InviteModal'

const ContactBook = () => {

  const [activeFilter, setActiveFilter] = useState('Alle')
  const [open, setOpen] = useState(false)


  return (
    <Box px={2}>
      <Box py={3}>
        <Typography variant="h4" pb={2}>
          Kontaktbuch
        </Typography>
        <Typography variant="body1" pb={2}>
          Laden Sie andere Mitglieder des Netzwerks in Ihr Kontaktbuch ein, um selbst weiterempfohlen zu werden. Das Kontaktbuch können nur Sie einsehen.
        </Typography>
        <Box width={'100%'} display="flex" justifyContent="flex-end">
          <Fab variant="extended" color="primary" onClick={() => setOpen(true)} >
            <PersonAddIcon sx={{ pr: 1 }} />
            Kontakte einladen
          </Fab>
          <InviteModal open={open} handleClose={() => setOpen(false)} />
        </Box>
      </Box>
      <Box py={1.5}>
        <Typography variant="h6" pl={'2px'}>
          Filter nach
        </Typography>
        {['Alle', 'Connector', 'Standard'].map((filter) => (
          <Chip label={filter} sx={{ mr: 1, mb: 3 }} onClick={() => setActiveFilter(filter)} variant={activeFilter === filter ? 'filled' : 'outlined'} />
        ))}
      </Box>
      <ContactList filter={activeFilter} />
    </Box >

  )
}

export default ContactBook