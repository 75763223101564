
import React, { useEffect, useState } from 'react'
import { Admin, Connector, DocRef, User } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import { firebaseGetContacts } from '../../services/user'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ContactModal from './ContactModal'
import UserInfo from './UserInfo'

interface ContactCardProps {
  contactRef: DocRef
  contactProp?: User | Connector | Admin
  children?: JSX.Element
  sx?: any
}

const ContactCard = (props: ContactCardProps) => {

  const [contact, setContact] = useState<User | Connector | Admin>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (props.contactProp) {
      setContact(props.contactProp)
    } else {
      firebaseGetContacts({ contacts: [props.contactRef] }).then((contact) => {
        setContact(contact[0])
      })
    }
  }, [props.contactRef, props.contactProp])

  return (
    <Box display="flex" justifyContent="space-between" sx={props.sx}>
      {contact && <UserInfo contact={contact}>
        <>
          <Button variant="text" color="primary" endIcon={<ArrowForward />} onClick={() => setOpen(true)}>
            Profil ansehen
          </Button>
          {contact && <ContactModal contact={contact} open={open} handleClose={() => setOpen(false)} />}
        </>
      </UserInfo>}
      <Box>
        {props.children}
      </Box>
    </Box>
  )
}

export default ContactCard
