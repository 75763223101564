import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConnectContact from '../../components/ConnectContact'


const VermittelnScreen = () => {

  return (
    <Box>
      <Typography variant="h4" py={3}>
        Kontakte Vermitteln
      </Typography>
      <ConnectContact />
    </Box>
  )
}

export default VermittelnScreen
