import React from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { industries, Inudstries } from '../types/dreico.types'
import { CustomAutocompleteProps } from '../types/forms.types'

interface IndustriesInputProps extends CustomAutocompleteProps {
  value: Inudstries[]
  error?: boolean
  helperText?: string[] | string | false
}

const IndustriesInput = (props: IndustriesInputProps) => {

  return (
    <Autocomplete
      multiple
      id="industries"
      value={props.value || []}
      onChange={props.onChange}
      options={industries}
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((industryName, index) => (
          <Chip
            label={industryName}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label="Branchen" placeholder="" name="industries" error={props.error} helperText={props.helperText} />
      )}
    />
  );
}

export default IndustriesInput