import { useContext, useState } from 'react'
import { auth } from '../services/firebase';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Navigate, useLocation } from "react-router";
import { sendEmailVerification, User } from "firebase/auth";
import { useInterval, useTimeout } from 'usehooks-ts'
import { UserContext } from '../contexts/UserContext';
import { firebaseGetUser } from '../services/user';
import { firebaseGetRequestsAsConnector, firebaseGetRequestsAsRequester } from '../services/request';
import { firebaseGetConnections } from '../services/connections';
import Layout from '../containers/Layout';

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  const [disabled, setDisabled] = useState(false)
  const [disabledTime, setDisabledTime] = useState<number | null>(null)

  const { user, setUser, setRequestsAsRequester, setRequestsAsConnector, setConnections } = useContext(UserContext)

  useTimeout(() => {
    setDisabled(false)
  }, disabledTime)

  useTimeout(() => {
    if (!auth.currentUser) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }, 100)

  useInterval(() => {
    if (auth.currentUser) {
      loadFirebaseData()
    }
  }, !user ? 200 : null)

  const loadFirebaseData = () => {
    firebaseGetUser({
      setUser: setUser
    })
    firebaseGetRequestsAsRequester({
      setRequests: setRequestsAsRequester
    })
    firebaseGetRequestsAsConnector({
      setRequests: setRequestsAsConnector
    })
    firebaseGetConnections({
      onSuccess: setConnections
    })
  }

  if (auth.currentUser && !auth.currentUser.emailVerified) {
    return (
      <Layout>
        <Box>
          <Typography>Bitte bestätigen Sie erst ihre Emailadresse!</Typography>
          <Button onClick={
            async () => {
              setDisabledTime(10000)
              setDisabled(true)
              await sendEmailVerification(auth.currentUser as User)
            }}
            disabled={disabled}>
            <Typography>Bestätigungsmail erneut senden</Typography>
          </Button>
        </Box>
      </Layout>
    )
  }

  return children;
}

export default RequireAuth;