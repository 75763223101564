import React, { useContext } from 'react'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import * as yup from 'yup'
import IndustriesInput from '../atoms/IndustriesInput'
import LocationInput from '../atoms/LocationInput'
import TagsInput from '../atoms/TagsInput'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import FormikWithShowingErrors from "./FormikWithShowingErrors";

const validationSchema = yup.object({
  inudstries: yup.array().of(
    yup.string()
  ).min(1, 'At least one industry is required'),
  location: yup.array().min(1, 'Bitte geben Sie eine Region an'),
  tags: yup.array().of(
    yup.object().shape({
      name: yup.string(),
    })
  ).min(3, 'Bitte geben Sie mindestens drei Tags an'),
})

interface NetworkFormProps {
  setEditMode: (editMode: boolean) => void
}

const NetworkForm = (props: NetworkFormProps) => {

  const { user } = useContext(UserContext)
  const { setEditMode } = props

  if (user?._type === 'user' || !user?._type) {
    return null
  }

  console.log(user)

  return (
    <>
      <FormikWithShowingErrors
        initialValues={{
          industries: user?.networkOntology?.industries || [],
          location: user?.networkOntology?.location || [],
          tags: user?.networkOntology?.tags || [],
        }}

        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          user && await firebaseSetUser({
            values: {
              networkOntology: {
                _type: 'networkOntology',
                businessType: null,
                industries: values.industries,
                location: values.location,
                tags: values.tags,
              },
            },
            actionType: 'updateNetworkData',
            user: user,
            onSuccess: () => setEditMode(false)
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Regionen
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie die Regionen aus, in denen Sie besonders gut vernetzt sind.
                </Typography>
                <LocationInput
                  value={formik.values.location}
                  formik={formik}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location as string | string[] | undefined}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Branchen
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie weitere Branchen aus, in denen Sie besonders gut vernetzt sind.
                </Typography>
                <IndustriesInput
                  value={formik.values.industries}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('industries', newValue)
                  }}
                  error={formik.touched.industries && Boolean(formik.errors.industries)}
                  helperText={formik.touched.industries && formik.errors.industries}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Schlagwörter
                </Typography>
                <Typography variant="h6" pb={3}>
                  Fügen Sie Schlagwörter hinzu, die weitere Berufsgruppen in Ihrem Netzwerk beschreiben.
                </Typography>
                <TagsInput
                  value={formik.values.tags}
                  formik={formik}
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={formik.touched.tags && formik.errors.tags as string[] | string | undefined}
                />
              </CardContent>
            </Card>
            <Button color="primary" variant="contained" type="submit">
              Speichern
            </Button>
          </Box>
        )}
      </FormikWithShowingErrors>

    </>
  )
}

export default NetworkForm