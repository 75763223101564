import { Edit as EditIcon } from '@mui/icons-material'
import { Box, CardContent, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import DescriptionCard from '../../atoms/DescriptionCard'
import { styles } from '../../styles/styles'

interface ProfileHeaderProps {
  tabs?: string[]
  activeTab?: string
  setActiveTab?: (tab: string) => void
  editMode: boolean
  setEditMode: (state: boolean) => void
}

const ProfileHeader = (props: ProfileHeaderProps) => {

  const { tabs, activeTab, setActiveTab, editMode, setEditMode } = props

  return (
    <Box p={2}>
      <Typography variant="h4" pb={2}>
        Ihr Profil
      </Typography>
      <DescriptionCard>
        <CardContent>
          <Typography variant="body1">
            Hier können Sie Ihre persönlichen Daten einsehen und bearbeiten.
          </Typography>
        </CardContent>
      </DescriptionCard>
      <Box py={2} overflow={'auto'} whiteSpace={'nowrap'} mr={'-1rem'}>
        {tabs && tabs.map(tab => (
          <Box key={tab} className={tab === activeTab ? 'active' : undefined} sx={{
            display: 'inline-block',
            '&.active': {
              borderBottom: `2px solid ${styles.PRIMARY_COLOR_ACTIVE}`,
              color: styles.PRIMARY_COLOR_ACTIVE,
            },
            '&:first-child': { h6: { paddingLeft: 0 } }
          }}
            onClick={() => {
              setActiveTab && setActiveTab(tab)
              tab !== activeTab && setEditMode && setEditMode(false)
            }}>
            <Typography variant="h6" p={2} sx={{}}>
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>
      {
        !editMode && <Box justifyContent="flex-end" display="flex" onClick={() => setEditMode(true)}>
          <Button variant="text" endIcon={<EditIcon />}>Profil Bearbeiten</Button>
        </Box>
      }
    </Box >

  )
}

export default ProfileHeader