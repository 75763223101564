import React, { useContext, useState } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { UserContext } from '../../contexts/UserContext'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ContactRequest } from '../../types/dreico.types'
import { Chip } from '@mui/material'
import ContactCard from '../ContactCard'
import Button from '@mui/material/Button'

interface ListOfRequestProps {
  filter: 'new' | 'processed' | 'finished'
  setActiveRequest: (request: ContactRequest) => void
}

const ListOfRequests = (props: ListOfRequestProps) => {

  const { user, requestsAsConnector } = useContext(UserContext)
  const { filter, setActiveRequest } = props

  const [requestOpened, setRequestOpened] = useState(
    requestsAsConnector.map((request) => false)
  )

  // toggle element number i in array
  const toggleElementInArray = (i: number) => {
    const newArray = [...requestOpened]
    newArray[i] = !newArray[i]
    setRequestOpened(newArray)
  }

  const List = (props: {
    request: ContactRequest,
    index: number
  }) => {
    const { request, index } = props
    return (
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" pb={1}>
            <Chip label={request.requestOntology.businessType} variant="outlined" />
            <IconButton aria-label="expand" sx={{ p: 0 }} onClick={() => { toggleElementInArray(index) }}>
              {requestOpened[index] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Typography variant="h5" onClick={() => { toggleElementInArray(index) }} sx={{ cursor: 'pointer' }}>
            {request.title}
          </Typography>
          <Typography variant="body2">
            <Chip label={request.status === 'closed' ? 'Beendet' : 'Aktiv'} sx={{ my: 1, mr: 1, color: '#fff' }} color="primary" />
            {' ' + (new Date(request.createdAt)).toLocaleDateString()}
          </Typography>
          {requestOpened[index] && (
            <>
              <ContactCard contactRef={request.requesterId} sx={{ py: 4 }} />
              <Button variant="contained" onClick={() => { setActiveRequest(request) }}>Kontakt vermitteln</Button>
              <Typography variant="body1" sx={{ mt: 3 }}>
                {request.description}
              </Typography>
              <Typography variant="body1">

              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      {requestsAsConnector.map((request, index) => {
        if (user?.requests?.filter(r => r.view === filter).find(r => r.id === request._id))
          return (
            <List request={request} index={index} />
          )
        return null
      })}
    </>
  )
}

export default ListOfRequests
