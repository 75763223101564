import React from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useContext } from 'react'
import * as yup from 'yup'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import { defaultBusinessTypes, Inudstries } from '../types/dreico.types'
import Box from '@mui/system/Box'
import LocationInput from '../atoms/LocationInput'
import IndustriesInput from '../atoms/IndustriesInput'
import TagsInput from '../atoms/TagsInput'
import FormikWithShowingErrors from "./FormikWithShowingErrors";

const validationSchema = yup.object({
  businessType: yup.string().required('Business type is required'),
  companyName: yup
    .string()
    .required('Company name is required'),
  jobPosition: yup
    .string(),
  location: yup
    .array()
    .min(1, 'Bitte geben Sie eine Region an'),
  industries: yup
    .array().of(
      yup.string()
    ).min(1, 'At least one industry is required'),
  tags: yup
    .array().of(
      yup.object().shape({
        name: yup.string(),
      })
    )
    .min(3, 'Bitte geben Sie mindestens drei Tags an'),
  description: yup
    .string()
    .required('Description is required'),
})

interface BusinessFormProps {
  setEditMode: (editMode: boolean) => void
}

const BusinessForm = (props: BusinessFormProps) => {

  const { user } = useContext(UserContext)
  const { setEditMode } = props

  return (
    <>
      <FormikWithShowingErrors
        initialValues={{
          businessType: user?.business?.businessOntology.businessType || '',
          companyName: user?.business?.name || '',
          jobPosition: user?.business?.position || '',
          location: user?.business?.businessOntology.location || [],
          industries: user?.business?.businessOntology.industries || [],
          tags: user?.business?.businessOntology.tags || [],
          description: user?.business?.description || '',
        }}

        validationSchema={validationSchema}
        onSubmit={async (values) => {
          user && await firebaseSetUser({
            values: {
              business: {
                _type: 'business',
                businessOntology: {
                  _type: 'businessOntology',
                  businessType: values.businessType as 'Solopreneur' | 'Intrapreneur' | 'SME' | 'Corporation' | 'NGO' | 'Government' | 'Other',
                  // @ts-ignore untill location is dealt with
                  location: values.location,
                  industries: values.industries as Inudstries[],
                  tags: values.tags,
                },
                name: values.companyName,
                description: values.description,
                position: values.jobPosition,
                logo: ''
              }
            },
            actionType: 'updateBusinessData',
            user: user,
            onSuccess: () => setEditMode(false)
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Businesstyp
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie hier den Typ, der Ihr Business am Besten beschreibt.
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel>Businesstyp</InputLabel>
                    <Select
                      id="businessType"
                      name="businessType"
                      value={formik.values.businessType}
                      onChange={formik.handleChange}
                      error={formik.touched.businessType && Boolean(formik.errors.businessType)}
                    >
                      {defaultBusinessTypes.map((businessType) => (
                        <MenuItem key={businessType} value={businessType}>
                          {businessType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Name Ihres Business
                </Typography>
                <Typography variant="h6" pb={3}>
                  Geben Sie hier den Namen Ihres Business ein.
                </Typography>
                <TextField
                  fullWidth
                  id='companyName'
                  name='companyName'
                  label='Name'
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Ihre Position
                </Typography>
                <Typography variant="h6" pb={3}>
                  Geben Sie hier Ihre Position im Business ein. Als Solopreneur können Sie das Feld leer lassen.
                </Typography>
                <TextField
                  fullWidth
                  id='jobPosition'
                  name='jobPosition'
                  label='Ihre Position'
                  value={formik.values.jobPosition}
                  onChange={formik.handleChange}
                  error={formik.touched.jobPosition && Boolean(formik.errors.jobPosition)}
                  helperText={formik.touched.jobPosition && formik.errors.jobPosition}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Standorte
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie die Standorte, an denen Ihr Business ansässig ist.
                </Typography>
                <LocationInput
                  value={formik.values.location}
                  formik={formik}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location as string | string[] | undefined}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Branchen
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie die Branchen, in denen Ihr Business tätig ist.
                </Typography>
                <IndustriesInput
                  value={formik.values.industries}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('industries', newValue)
                  }}
                  error={formik.touched.industries && Boolean(formik.errors.industries)}
                  helperText={formik.touched.industries && formik.errors.industries}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Beschreibung
                </Typography>
                <Typography variant="h6" pb={3}>#
                  Geben Sie hier eine kurze Beschreibung Ihres Business ein.
                </Typography>
                <TextField
                  fullWidth
                  id='description'
                  name='description'
                  label='Beschreibung'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={3}>
                  Schlagwörter
                </Typography>
                <Typography variant="h6" pb={3}>
                  Wählen Sie die Schlagwörter, die Ihr Business am Besten beschreiben.
                </Typography>
                <TagsInput
                  value={formik.values.tags}
                  formik={formik}
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={formik.touched.tags && formik.errors.tags as string[] | string | undefined}
                />
              </CardContent>
            </Card>
            <Button color="primary" variant="contained" type="submit">
              Speichern
            </Button>
          </Box>
        )}
      </FormikWithShowingErrors>
    </>
  )
}

export default BusinessForm