import React, { useState } from 'react'
import Filters from '../../atoms/Filters'
import { ContactRequest, DocRef } from '../../types/dreico.types'
import ListOfRequests from './ListOfRequests'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ContactCard from '../ContactCard'
import ConnectContactsForm from '../../forms/ConnectContactsForm'
import ConnectModal from './ConnectModal'

const tabs = [
  {
    label: 'Neue Anfragen',
    value: 'new'
  },
  {
    label: 'Bearbeitete Anfragen',
    value: 'processed'
  },
  {
    label: 'Beendete Anfragen',
    value: 'finished'
  }
]

const ConnectContact = () => {

  const [activeTab, setActiveTab] = useState(tabs[0].value)
  const [activeRequest, setActiveRequest] = useState<ContactRequest>()
  const [contactSelected, setContactSelected] = useState<DocRef>()

  return (
    <>
      {!activeRequest ? (
        <>
          <Filters tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <ListOfRequests filter={activeTab as 'new' | 'processed' | 'finished'} setActiveRequest={setActiveRequest} />
        </>
      ) : contactSelected ?
        (<>
          <Box>
            <Typography variant="h4">
              Vernetzung zwischen
            </Typography>
            <ContactCard contactRef={activeRequest.requesterId} sx={{ py: 4 }} />
            <ContactCard contactRef={contactSelected} sx={{ py: 4 }} />
            <ConnectContactsForm requesterId={activeRequest.requesterId} connectedUserId={contactSelected} requestId={activeRequest._id} request={activeRequest} />
          </Box>
        </>)
        : <ConnectModal request={activeRequest} handleSubmit={setContactSelected} />}
    </>
  )
}

export default ConnectContact