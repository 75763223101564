import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'

const BusinessCards = () => {

  const { user } = useContext(UserContext)

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Businesstyp
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.businessType}
          </Typography>
        </CardContent>
      </Card>
      {user?.business?.name && <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Name Ihres Business
          </Typography>
          <Typography variant="body1">
            {user?.business?.name}
          </Typography>
        </CardContent>
      </Card>}
      {user?.business?.position && <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Ihre Position
          </Typography>
          <Typography variant="body1">
            {user?.business?.position}
          </Typography>
        </CardContent>
      </Card>}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Standorte
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.location.map((loc) => (
              <Chip
                label={loc.structured_formatting.secondary_text}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Branchen
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.industries.map((ind) => (
              <Chip
                label={ind}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Schlagwörter
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.tags.map((tag) => (
              <Chip
                label={tag.name}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Kurzbeschreibung
          </Typography>
          <Typography variant="body1">
            {user?.business?.description}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default BusinessCards