import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { User, Connector, Admin } from '../../types/dreico.types'

interface UserInfoProps {
  contact: User | Connector | Admin
  children?: JSX.Element
  sx?: any
}

const UserInfo = (props: UserInfoProps) => {

  const { contact, children, sx } = props

  return (
    <Box display={'flex'} sx={sx}>
      <Avatar sx={{ height: 60, width: 60 }}></Avatar>
      <Box px={2}>
        <Typography variant="h6">
          {contact?.firstName} {contact?.lastName}
        </Typography>
        <Typography variant="body1" color="primary">
          {contact?._type !== 'user' && 'Connector'}
        </Typography>
        {contact?.business?.position &&
          <Typography variant="body1">
            {contact?.business?.position}
          </Typography>
        }
        {contact?.business?.name &&
          <Typography variant="body1" color="secondary">
            {contact?.business?.name}
          </Typography>
        }
        {contact?.business?.businessOntology &&
          <Typography variant="body1" color="secondary">
            {contact?.business?.businessOntology.businessType}
          </Typography>
        }
        {contact?.business?.businessOntology.location &&
          <Typography variant="body1" color="secondary" mb={2}>
            {contact?.business?.businessOntology.location.map(l => l.structured_formatting.secondary_text).join(', ')}
          </Typography>
        }
        {children}
      </Box>
    </Box>
  )
}

export default UserInfo
