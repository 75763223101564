import React from 'react'
import { BusinessOntology, NetworkOntology, RequestOntology } from '../types/dreico.types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

interface DisplayedOntologyProps {
  title?: string
  ontology: RequestOntology | NetworkOntology | BusinessOntology
}

const DisplayedOntology = (props: DisplayedOntologyProps) => {

  const { title, ontology } = props

  return (
    <Box maxWidth={800} m={'auto'}>
      {title &&
        <Typography variant="h5" sx={{ pl: 2, mt: 4, mb: 2 }}>
          {title}
        </Typography>
      }

      {
        ontology.businessType && ontology.businessType.length > 0 &&
        (<Box px={2} pb={2}>
          <Typography variant="h6">
            Businesstyp
          </Typography>
          {ontology._type === 'requestOntology' && ontology.businessType.map((businessType, i) => (
            <Typography key={businessType + i} >
              {businessType}
            </Typography>))}
        </Box>)
      }

      {
        ontology.location && (
          <Box px={2} pb={2}>
            <Typography variant="h6">
              Regionen
            </Typography>
            <Box pt={1} sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'flex-start' }}>
              {ontology.location.map((l, i) => (
                <Chip key={l.structured_formatting.secondary_text + i} sx={{ bgcolor:'#dedede', mr: 1, mb: 1 }} label={l.structured_formatting.secondary_text} />
              ))}
            </Box>
          </Box>
        )
      }

      {
        ontology.industries && (
          <Box px={2} pb={2}>
            <Typography variant="h6">
              Branchen
            </Typography>
            <Box pt={1}>
              {ontology.industries.map((industry, ind) => (
                <Chip key={industry + ind} sx={{ bgcolor:'#dedede', mr: 1, mb: 1 }} label={industry} />
              ))}
            </Box>
          </Box>
        )
      }

      {
        <Box px={2} pb={2}>
          <Typography variant="h6">
            Schlagwörter
          </Typography>
          <Box pt={1}>
            {ontology.tags.map((tag, i) => (
              <Chip key={tag.name + i} sx={{ bgcolor:'#dedede', mr: 1, mb: 1 }} label={tag.name} />
            ))}
          </Box>
        </Box>
      }
    </Box>
  )
}

export default DisplayedOntology
