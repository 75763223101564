import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { firebaseGetContacts } from '../../services/user'
import { Admin, Connector, User } from '../../types/dreico.types'
import { ShowMessageContext } from "../../contexts/ShowMessageContext";
import ContactCard from '../ContactCard'

interface ContactListProps {
  filter: string
}

const ContactList = (props: ContactListProps) => {

  const { user } = useContext(UserContext)
  const [contacts, setContacts] = useState<(User | Connector | Admin)[]>([])

  const { showErrorMessage } = useContext(ShowMessageContext)

  const { filter } = props

  useEffect(() => {
    if (user) {
      firebaseGetContacts({
        contacts: user.contacts,
      })
        .then((contacts) => {
          setContacts(contacts)
        })
        .catch((error) => {
          showErrorMessage(error);
        }
        )
    }
  }, [showErrorMessage, user])

  return (
    <>
      {contacts.filter((c) => {
        if (filter === 'Connectors') {
          return c._type !== 'user'
        } else if (filter === 'Standard') {
          return c._type === 'user'
        } else return true
      }).map(contact => (
        <ContactCard
          contactProp={contact}
          key={contact._id}
          contactRef={contact._id}
          sx={{ mb: 2, py: 4 }}
        />
      ))}
    </>
  )
}

export default ContactList