import React, { useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { UserContext } from '../../contexts/UserContext'


const NetworkCards = () => {

  const { user } = useContext(UserContext)

  if (user?._type === 'user' || !user?._type) {
    return <></>
  }

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Regionen
          </Typography>
          <Typography variant="body1">
            {user?.networkOntology?.location.map((loc) => (
              <Chip
                label={loc.structured_formatting.secondary_text}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Branchen
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.industries.map((ind) => (
              <Chip
                label={ind}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            Schlagwörter
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.tags.map((tag) => (
              <Chip
                label={tag.name}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default NetworkCards