import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { UserContext } from '../../contexts/UserContext';
import VermittelnScreen from './VermittelnScreen';
import AnfragenScreen from './AnfragenScreen';
import { Button, Card, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const Dashboard = () => {

  const { user } = useContext(UserContext)
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          m: [0, 4],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card sx={{py: 3, px: [4, 12, 24], mb: '4rem', backgroundColor: '#536DFE', color: '#fff', textAlign: 'center'}} variant="elevation">
            <Typography variant={'h5'} mb={2}>
              Guten Tag {user?.firstName} {user?.lastName},
            </Typography>
            <Typography variant={'h6'} mb={2}>
              herzlich willkommen bei DreiCo!
            </Typography>
            <Typography mb={2}>
              Sie sind nun teil eines vertrauensvollen Netzwerks in welchem Sie sich mit anderen Experten und Netwerker*innen verknüpfen können.
            </Typography>
            <Typography mb={2}>
              Füllen Sie ihr Profil aus, damit Sie Kontaktanfragen starten oder andere Anfragen vernetzen können.
            </Typography>
            <Button variant="outlined" color="inherit" endIcon={<ArrowForward />} onClick={() => navigate('/profile')}>
              Profil einstellen
            </Button>
        </Card>

        <AnfragenScreen />
        {user?._type !== 'user' && <VermittelnScreen />}
      </Box>
    </>
  );
};

export default Dashboard;