export const AppRoutes = {
    ROOT: "*",
    LOGIN: "/login",
    CONNECTOR_SIGNUP: "/connector-signup",
    SIGNUP: "/signup",
    RESET_PASSWORD: "/reset-password",
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    REQUESTS: "/requests",
    ANFRAGEN: "/anfragen",
    VERMITTELN: "/vermitteln",
    KONTAKTANFRAGE: "/kontaktanfrage",
    VERNETZUNGEN: "/vernetzungen",
    CONTACT_BOOK: "/kontaktbuch",
    SETTINGS: "/einstellungen",
}