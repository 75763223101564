import { auth, db } from './firebase'
import { doc, setDoc, arrayUnion, onSnapshot, collection, where, query, getDoc } from "firebase/firestore"
import { Connection, ContactRequest, DocRef } from "../types/dreico.types"
import { ErrorWithDetails } from '../utility/error-handling/error-handling-utility'
import { v1 as uuidv1 } from 'uuid'

interface CreateConnectionVars {
  values: {
    message: string
  }
  requestId: DocRef
  request: ContactRequest
  requesterId: DocRef
  connectedUserId: DocRef
  onSuccess?: () => void
  showErrorMessage?: (error: any, userFriendlyMessage?: string | undefined) => void
}

export const firebaseCreateConnection = async (vars: CreateConnectionVars) => {
  /** 
   * Create a new request.
   */
  const { values, requestId, requesterId, request, connectedUserId, onSuccess, showErrorMessage } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const id = uuidv1()
  const connectionRef = doc(db, 'connections', id)
  const userRef = doc(db, 'users', auth.currentUser.uid, 'private', 'userData')
  const currentUser = await (await getDoc(userRef)).data()

  const requestArray = currentUser?.requests.filter((el: any) => {
    if (el.id === requestId) {
      el.view = 'processed'
    }
    return el
  })

  delete request.connectedUserIds
  delete request.connectorIds

  console.log(requestArray);

  const connection: Connection = {
    _type: 'connection',
    _id: id,
    createdAt: new Date(),
    message: values.message,
    requestSnapshot: request,
    requesterId: requesterId,
    connectedUserId: connectedUserId,
    connectorId: auth.currentUser.uid,
    requestId: requestId,
    allowRead: [requesterId, connectedUserId, auth.currentUser.uid],
  }

  const reqRef = doc(db, "requests", requestId)


  try {
    await setDoc(connectionRef, connection)
    await setDoc(userRef, {
      connections: arrayUnion({
        id: id,
        role: 'connector'
      }),
      requests: requestArray
    }, { merge: true })
    await setDoc(userRef, {
      requests: requestArray
    }, { merge: true })
    // add connectedUserId to the request
    await setDoc(reqRef, {
      connectedUserIds: arrayUnion(connectedUserId)
    }, { merge: true })
    onSuccess && onSuccess()
  }
  catch (error) {
    showErrorMessage && showErrorMessage(new ErrorWithDetails(error, "Error creating connection"))
  }
}

interface GetConnectionsVars {
  onSuccess: (requests: Connection[]) => void
}

export const firebaseGetConnections = async (vars: GetConnectionsVars) => {
  /** 
   * Get all connections.
   */

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const { onSuccess } = vars

  const q =
    query(collection(db, 'connections'),
      where('allowRead', 'array-contains', auth.currentUser.uid))

  try {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const connections: Connection[] = []
      console.log(snapshot)
      snapshot.forEach((doc) => {
        console.log(doc)
        connections.push(doc.data() as Connection)
      })
      onSuccess(connections)
    })
    return unsubscribe
  } catch (error) {
    throw new ErrorWithDetails(error, "Error getting connections")
  }
}