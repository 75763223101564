import React, { useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { UserContext } from '../../contexts/UserContext'

const KontaktvermittelnScreen = () => {

  let { id } = useParams()

  const { requestsAsConnector } = useContext(UserContext)

  useEffect(() => {
    console.log(id)
  }, [id])

  return (
    <>
      {requestsAsConnector?.find(r => {
        if (r._id === id)
          return (
            <Box>
              <Typography variant="h4">
                {r.title}
              </Typography>
            </Box>
          )
        return null
      })}
    </>
  )
}

export default KontaktvermittelnScreen