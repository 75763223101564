import React, { useContext, useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'
import { UserContext } from '../../contexts/UserContext'
import { firebaseGetAllConnectorsAndAdmins, firebaseGetContacts } from '../../services/user'
import { User, Connector, Admin } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ContactCard from '../../components/ContactCard'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

interface SelectConnectorFormProps {
  formik: FormikProps<any>
}

const SelectConnectorForm = (props: SelectConnectorFormProps) => {

  const { formik } = props

  const { user } = useContext(UserContext)
  const [connectors, setConnectors] = useState<(User | Connector | Admin)[]>([])
  const { showErrorMessage } = useContext(ShowMessageContext)

  useEffect(() => {
    if (user?._type !== 'user') {
      firebaseGetAllConnectorsAndAdmins().then((connectors) => {
        setConnectors(connectors)
      }).catch((error) => {
        showErrorMessage(error)
      })
    } else {
      firebaseGetContacts({
        contacts: user.contacts,
        type: 'connector'
      }).then((connectors) => {
        setConnectors(connectors)
      }).catch((error) => {
        showErrorMessage(error)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Typography variant="h4">
        Connectoren mit Kontakten für Sie
      </Typography>
      <Typography variant="body1">
        Wählen Sie die Connectoren, denen Sie die Anfrage senden möchten und klicken Sie anschließend auf "Anfrage senden".
      </Typography>
      <FormGroup>
        {connectors.map((connector) => {
          if (connector._id !== user?._id)
            return (
              <ContactCard key={connector._id} contactProp={connector} contactRef={connector._id} sx={{ py: 4 }} >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.connectorIds.includes(connector._id)}
                      onChange={formik.handleChange}
                      name="connectorIds"
                      value={connector._id}
                    />
                  }
                  label={''}
                />
              </ContactCard>
            )
          return null
        })}
      </FormGroup>
    </Box>
  )
}

export default SelectConnectorForm