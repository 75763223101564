import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore'
import { UserContextType } from '../contexts/UserContext'
import { UpdateProfileData, UpdateBusinessData, UpdateNetworkData } from '../types/actions.types'
import { Admin, Connector, User } from '../types/dreico.types'
import { XOR } from '../types/generic.types'
import {auth, db, firebaseFunctions} from './firebase'
import { ErrorWithDetails } from "../utility/error-handling/error-handling-utility";
import { httpsCallable } from 'firebase/functions'

interface CreateUserVars {
  values: any
  isConnector?: boolean,
  inviteId: null | string,
  onSuccess?: () => void
}

async function createUser(vars: CreateUserVars, isConnector: boolean | undefined, onSuccess: (() => void) | undefined) {
  try {
    // create user with email and password
    const user = await createUserWithEmailAndPassword(auth, vars.values.email, vars.values.password);
    if (user) {
      await sendEmailVerification(user.user)
      if (auth.currentUser?.uid) {
        await setDoc(doc(db, 'users', auth.currentUser.uid), {
          firstName: vars.values.firstName,
          lastName: vars.values.lastName,
          _type: isConnector ? 'connector' : 'user',
          _id: auth.currentUser.uid,
        })
        await setDoc(doc(db, 'users', auth.currentUser.uid, 'private', 'userData'), {
          email: vars.values.email,
          phoneNumber: vars.values.phoneNumber,
        })
        onSuccess && onSuccess()
      }
    }
  } catch (error) {
    throw new ErrorWithDetails(error, "creating user")
  }
}

// Needs to be equal to InviteData in repository dreico-functions.
interface InviteData {
  inviteId: undefined | null | string,
  invitedId: undefined | null | string
}

async function checkInvite(isConnector: boolean | undefined, inviteId: string | null) {
  if (inviteId == null) {
    throw new ErrorWithDetails("Sign-up URL contained no invite.", "checking invite")
  } else {
    // Check if invite is unused.
    const docSnap = await getDoc(doc(db, 'invites', inviteId))
    if (!docSnap.exists() || docSnap.get('status') !== 'unused') {
      throw new ErrorWithDetails("Invite does not exist or was already used.", "checking invite")
    }
  }
}

export const firebaseCreateUser = async (vars: CreateUserVars) => {
  const { onSuccess, isConnector, inviteId } = vars

  // If user is of type user, check if they have provided an inviteId which is unused.
  // Note: It is a requirement invited users are allowed to use a different e-mail than the one they were invited to.
  if (!isConnector) {
    await checkInvite(isConnector, inviteId)
  }
  await createUser(vars, isConnector, onSuccess);

  // If user is of type user, execute firebase function consumeInvite.
  // TODO: Handle creating user and consuming invite in a transaction. Currently, the user stays registered, even if consuming the invite fails.
  if (!isConnector) {
    const consumeInvite = httpsCallable(firebaseFunctions, 'consumeInvite')
    // If firebase function returns an error, consumeInvite() will throw it (https://firebase.google.com/docs/functions/callable#handle_errors_on_the_client).
    const inviteData: InviteData = {
      inviteId: inviteId, invitedId: auth.currentUser?.uid
    }
    await consumeInvite(inviteData)
  }
}

interface GetUserVars extends Pick<UserContextType, 'setUser' | 'user'> {
}

export const firebaseGetUser = async (vars: GetUserVars) => {

  const { user, setUser } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const publicDocRef = doc(db, "users", auth.currentUser.uid)
  const privateDocRef = doc(db, "users", auth.currentUser.uid, "private", "userData")

  onSnapshot(publicDocRef, async (doc) => {
    if (doc.exists()) {
      console.log('updating user')
      const privateData = await getDoc(privateDocRef)
      if (privateData.exists()) {
        setUser({
          ...user,
          ...doc.data() as User | Connector | Admin,
          ...privateData.data() as User | Connector | Admin,
        })
      }

    } else {
      console.log('No such document!')
    }
  })
}

interface SetUserVars {
  values: XOR<XOR<UpdateProfileData, UpdateBusinessData>, UpdateNetworkData>
  actionType: 'updateProfileData' | 'updateBusinessData' | 'updateNetworkData'
  user: User | Connector | Admin
  onSuccess?: () => void
}

export const firebaseSetUser = async (vars: SetUserVars) => {
  const { values, actionType, user, onSuccess } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const privateDocRef = doc(db, "users", auth.currentUser.uid, "private", "userData")
  const publicDocRef = doc(db, "users", auth.currentUser.uid)


  if (actionType === 'updateProfileData') {
    const publicData = {
      firstName: values.firstName || user.firstName,
      lastName: values.lastName || user.lastName,

    }

    const privateData = {
      email: values.email || user.email,
      phoneNumber: values.phoneNumber,
    }

    try {
      await setDoc(privateDocRef, privateData, { merge: true })
      await setDoc(publicDocRef, publicData, { merge: true })
      onSuccess && onSuccess()

    } catch (error) {
      throw new ErrorWithDetails(error, "updating user")
    }
  }

  if (actionType === 'updateBusinessData') {
    const data = {
      business: {
        _type: 'business' as 'business',
        businessOntology: values.business?.businessOntology,
        description: values.business?.description,
        logo: values.business?.logo,
        name: values.business?.name,
        position: values.business?.position,
      }
    }

    try {
      await setDoc(publicDocRef, data, { merge: true })
      onSuccess && onSuccess()

    } catch (error) {
      throw new ErrorWithDetails(error, "updating business")
    }
  }

  if (actionType === 'updateNetworkData') {
    const data = {
      networkOntology: values.networkOntology,
    }

    try {
      await setDoc(publicDocRef, data, { merge: true })
      onSuccess && onSuccess()

    } catch (error) {
      throw new ErrorWithDetails(error, "updating network")
    }
  }
}

export const firebaseGetAllAdmins = async () => {
  const admins: Admin[] = []
  const q = query(collection(db, 'users'), where('_type', '==', 'admin'))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    admins.push(doc.data() as Admin)
  })

  return admins
}

interface GetContactsVars {
  contacts?: string[]
  type?: 'user' | 'connector'
}

export const firebaseGetContacts = async (vars: GetContactsVars) => {

  const { contacts, type } = vars

  if (!contacts) {
    return []
  }

  const contactData: (User | Connector | Admin)[] = []

  const promises = contacts.map(async (uid) => {
    const docSnap = await getDoc(doc(db, 'users', uid))
    if (docSnap.exists()) {
      if (!type)
        contactData.push(docSnap.data() as User | Connector | Admin)
      else if (docSnap.data()?._type === type || (type === 'connector' && docSnap.data()?._type === 'admin'))
        contactData.push(docSnap.data() as User | Connector | Admin)
    }
  })

  try {
    await Promise.all(promises)
  }
  catch (error) {
    throw new ErrorWithDetails(error, "retrieving contacts")
  }

  return contactData
}

export const firebaseGetAllConnectorsAndAdmins = async () => {
  const connectors: Connector[] = []
  const admins: Admin[] = []

  const q1 = query(collection(db, 'users'), where('_type', '==', 'connector'))
  const q2 = query(collection(db, 'users'), where('_type', '==', 'admin'))

  const querySnapshotConnector = await getDocs(q1)
  querySnapshotConnector.forEach((doc) => {
    connectors.push(doc.data() as Connector)
  })

  const querySnapshotAdmin = await getDocs(q2)
  querySnapshotAdmin.forEach((doc) => {
    admins.push(doc.data() as Admin)
  })

  return [...connectors, ...admins]
}