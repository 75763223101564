import React, { useContext, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ContactCard from '../ContactCard'
import IconButton from '@mui/material/IconButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Chip from '@mui/material/Chip'
import { Connection } from '../../types/dreico.types'
import { UserContext } from '../../contexts/UserContext'
// import Button from '@mui/material/Button'

const chiplabel = ['Von Ihnen angefragt', 'Von anderen angefragt']

interface Props {
  connection: Connection
}

const ListOfConnections = (props: Props) => {

  const { connection } = props
  const { user } = useContext(UserContext)
  const [expanded, setExpanded] = useState(false)

  const userIsRequester = useMemo(() => connection.requesterId === user?._id, [connection, user?._id])

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Chip variant="outlined" label={userIsRequester ? chiplabel[0] : chiplabel[1]} />
          <IconButton aria-label="expand" sx={{ p: 0 }} onClick={() => { setExpanded(!expanded) }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>

        <Typography variant="h5" mt={2} mb={1}>
          {connection.requestSnapshot?.title && connection.requestSnapshot.title}
        </Typography>
        <ContactCard contactRef={userIsRequester ? connection.connectedUserId : connection.requesterId} />
        {expanded && (
          <>
            <Typography variant="h6" mt={2} >
              Nachricht von Connector:
            </Typography>
            <Typography variant="body1" mb={1}>
              {connection.message}
            </Typography>
            {/* {connection.requestSnapshot && (
              <Button variant="text" onClick={() => { }}>
                Anfrage ansehen
              </Button>
            )} */}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default ListOfConnections