export const styles = {
    PRIMARY_COLOR: "#536DFE",
    PRIMARY_COLOR_ACTIVE: "#0043CA",
    PRIMARY_COLOR_BACKGROUND: '#322E48',

    SECONDARY_COLOR: "#322E4880",
    SECONDARY_COLOR_ACTIVE: "#536DFE80",
    SECONDARY_COLOR_BACKGROUND: '#0043CA80',

    WHITE: "#ffffff",
}
