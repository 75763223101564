import React, { useContext, useState } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { UserContext } from '../../contexts/UserContext'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import DisplayedOntology from '../DisplayedOntology'

interface ListOfRequestProps {
}

const ListOfRequests = (props: ListOfRequestProps) => {

  const { requestsAsRequester } = useContext(UserContext)
  const [requestOpened, setRequestOpened] = useState(
    requestsAsRequester.map((request) => false)
  )

  // toggle element number i in array
  const toggleElementInArray = (i: number) => {
    const newArray = [...requestOpened]
    newArray[i] = !newArray[i]
    setRequestOpened(newArray)
  }

  return (
    <>
      {requestsAsRequester.length && <Typography variant="h5" pb={4}>Gesendete Anfragen</Typography>}
      {requestsAsRequester.map((request, index) => (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Chip variant="outlined" label={request.requestOntology.businessType} />
              <IconButton aria-label="expand" onClick={() => { toggleElementInArray(index) }}>
                {requestOpened[index] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
            <Typography variant="h5">
              {request.title}
            </Typography>
            <Box display="flex" alignItems="center" pt={1}>
              <Chip label={request.status === 'closed' ? 'Beendet' : 'Aktiv'} color="primary" />
              <Typography variant="body2" sx={{ pl: 1 }}>
                {(new Date(request.createdAt)).toLocaleDateString()}
              </Typography>
            </Box>

            {requestOpened[index] && (
              <>
                <Typography variant="body1">
                  Angefragte Connectoren
                </Typography>
                {request.connectorIds?.map((connectorId) => (
                  <Typography variant="body2">
                    {connectorId}
                  </Typography>
                ))}
                <Typography variant="h6">
                  Beschreibung
                </Typography>
                <Typography variant="body1">
                  {request.description}
                </Typography>
                <Typography variant="body2">
                  Teilen Sie den bereits angefragten Connectoren mit, wenn die Anfrage beendet ist.
                </Typography>
                <Button variant="contained" color="primary">
                  Anfrage beenden
                </Button>
                <DisplayedOntology ontology={request.requestOntology} title={"Gesuchtes Businessprofil"} />
              </>
            )}
          </CardContent>
        </Card>
      ))}
    </>
  )
}

export default ListOfRequests