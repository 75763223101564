import { createTheme } from "@mui/material";
import { styles } from "./styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: styles.PRIMARY_COLOR,
            dark: '#0043CA',
            light: '#8F9BFF',
            contrastText: '#8F9BFF',
        },
        text: {
            primary: 'rgba(10, 9, 66, 0.87)',
            secondary: 'rgba(10, 9, 66, 0.6)'
        },
        background: {
            paper: '#fff',
            default: '#FAFBFC'
        },
        secondary: {
            main: styles.SECONDARY_COLOR,
        },
    },
    components: {
        MuiModal: {
            styleOverrides: {
                root: {
                    margin: '2rem',
                    overflow: 'scroll',
                    borderRadius: '1rem'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: styles.PRIMARY_COLOR,
                    color: styles.WHITE,
                    fontWeight: 400,
                    textTransform: 'none',
                    "&:hover": {
                        backgroundColor: styles.PRIMARY_COLOR_ACTIVE,
                    },
                },
                text: {
                    textTransform: 'none',
                    paddingLeft: 0,
                }
            },
        },
        MuiFab: {
            styleOverrides: {
                extended: {
                    backgroundColor: styles.PRIMARY_COLOR,
                    color: styles.WHITE,
                    fontWeight: 400,
                    textTransform: 'none',
                    "&:hover": {
                        backgroundColor: styles.PRIMARY_COLOR_ACTIVE,
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: styles.PRIMARY_COLOR_BACKGROUND,
                    color: "white",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    'borderRadius': '15px',
                }
            }
        },
    },
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSize: 16,
        h1: {
            fontFamily: ['Satoshi-Light', 'Roboto', 'sans-serif'].join(','),
            fontSize: 96,
        },
        h2: {
            fontFamily: ['Satoshi-Light', 'Roboto', 'sans-serif'].join(','),
            fontSize: 60,
        },
        h3: {
            fontFamily: ['Satoshi-Regular', 'Roboto', 'sans-serif'].join(','),
            fontSize: 48,
        },
        h4: {
            fontFamily: ['Satoshi-Regular', 'Roboto', 'sans-serif'].join(','),
            fontSize: 34,
        },
        h5: {
            fontFamily: ['Satoshi-Medium', 'Roboto', 'sans-serif'].join(','),
            fontSize: 24,
        },
        h6: {
            fontFamily: ['Satoshi-Medium', 'Roboto', 'sans-serif'].join(','),
            fontSize: 20,
        },
        body1: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 16,
        },
        body2: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 14,
        },
        subtitle1: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 16,
        },
        subtitle2: {
            fontFamily: ['Satoshi', 'Roboto', 'sans-serif'].join(','),
            fontSize: 14,
        },
        overline: {
            fontFamily: ['Satoshi', 'Roboto', 'sans-serif'].join(','),
            fontSize: 12,
        },
    },
});
