import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { FormikProps } from 'formik'
import TextField from '@mui/material/TextField'
import TagsInput from '../../atoms/TagsInput'
import LocationInput from '../../atoms/LocationInput'
import IndustriesInput from '../../atoms/IndustriesInput'
import BusinessTypeInput from '../../atoms/BusinessTypeInput'

// const desiredResultOptions = [
//   'Produkt / Dienstleistung',
//   'Unternehmensberater',
//   'Expertengespräch',
//   'Mitarbeiter',
//   'Innovationspartnerschaft',
//   'Unternehmensverkäufer',
//   'Unternehmenskäufer',
// ]

interface RequestDescriptionFormProps {
  formik: FormikProps<{
    title: string;
    description: string;
    businessType: never[];
    location: never[];
    industries: never[];
    tags: never[];
  }>
}

const RequestDescriptionForm = (props: RequestDescriptionFormProps) => {

  const { formik } = props

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Titel
          </Typography>
          <Typography variant="body1" pb={2}>
            Geben Sie Ihrer Anfrage einen kurzen Titel.
          </Typography>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Titel"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

        </CardContent>
      </Card>
      {/* <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Wonach suchen Sie?
          </Typography>
          <FormControl
            error={formik.touched.desiredResult && Boolean(formik.errors.desiredResult)}

          >
            <RadioGroup>
              {desiredResultOptions.map((option) => (
                <FormControlLabel
                  control={<Radio />}
                  label={option}
                  value={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card> */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Beschreibung
          </Typography>
          <Typography variant="body1" pb={2}>
            Beschreiben Sie Ihre Anfrage so detailliert wie möglich.
          </Typography>
          <TextField
            multiline
            minRows={4}
            fullWidth
            id="description"
            name="description"
            label="Beschreibung"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </CardContent>
      </Card>
      <Box>
        <Typography variant="h4" pb={2}>
          Welches Businessprofil suchen Sie?
        </Typography>
        <Typography variant="body1" pb={2}>
          Diese Angaben helfen unserem Algorithmus, Connectoren für eine Kontaktvermittlung zu finden.
        </Typography>
      </Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Businesstyp
          </Typography>
          <Typography variant="body1" pb={2}>
            Welche Art von Unternehmen suchen Sie?
          </Typography>
          <BusinessTypeInput
            value={formik.values.businessType}
            onChange={(event, newValue) => {
              formik.setFieldValue('businessType', newValue)
            }}
            error={formik.touched.businessType && Boolean(formik.errors.businessType)}
            helperText={formik.touched.businessType && formik.errors.businessType} />
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Regionen
          </Typography>
          <Typography variant="body1" pb={2}>
            In welchen Regionen suchen Sie?
          </Typography>
          <LocationInput
            value={formik.values.location}
            formik={formik}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Branchen
          </Typography>
          <Typography variant="body1" pb={2}>
            In welchen Branchen suchen Sie?
          </Typography>
          <IndustriesInput
            value={formik.values.industries}
            onChange={(event, newValue) => {
              formik.setFieldValue('industries', newValue)
            }}
            error={formik.touched.industries && Boolean(formik.errors.industries)}
            helperText={formik.touched.industries && formik.errors.industries}
          />
        </CardContent>
      </Card>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4" pb={2}>
            Tags
          </Typography>
          <Typography variant="body1" pb={2}>
            Fügen Sie Tags hinzu, die Ihnen helfen, Ihre Anfrage zu finden.
          </Typography>
          <TagsInput
            value={formik.values.tags}
            formik={formik}
            error={formik.touched.tags && Boolean(formik.errors.tags)}
            helperText={formik.touched.tags && formik.errors.tags}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default RequestDescriptionForm