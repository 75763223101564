import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Fab from '@mui/material/Fab'
import { AppRoutes } from '../../routes/Routes'
import AddIcon from '@mui/icons-material/Add'
import ListOfRequests from '../../components/Requests/ListOfRequests'

const AnfragenScreen = () => {
  return (
    <Box py={3}>
      <Typography variant="h4" pb={4}>
        Ihre Anfragen
      </Typography>
      <Box width={'100%'} display="flex" justifyContent="flex-end" pb={4}>
        <Link to={AppRoutes.KONTAKTANFRAGE}>
          <Fab variant="extended" color="primary">
            <AddIcon sx={{ pr: 1 }} />
            Kontaktanfrage starten
          </Fab>
        </Link>
      </Box>
      <ListOfRequests />
    </Box>
  )
}

export default AnfragenScreen