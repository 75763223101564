import React from 'react';
import './App.css';
import AppRouter from './routes/AppRouter';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { theme } from './styles/theme';
import { UserContextProvider } from './contexts/UserContext';
import { SnackbarProvider } from "notistack";
import {ShowMessageContextProvider} from "./contexts/ShowMessageContext";

function App() {
  return (
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "center", vertical: "top"}}>
          <ShowMessageContextProvider>
            <AppRouter/>
          </ShowMessageContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </UserContextProvider>

  );
}

export default App;
